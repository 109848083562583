import gql from "graphql-tag";

const base = gql`
  fragment BasePrompt on BasePrompt {
    completion
    jsonCompletion
    app {
      name
    }
    type
    promptContext {
      appSnapshot {
        name
        categoryTree {
          primaryCategory
          secondaryCategories
        }
        businessDescription
      }
      facebookPosts(fields: "permalink_url,created_time")
    }
  }
`;

const commentAutoreply = gql`
  fragment CommentAutoreply on PromptCommentAutoreply {
    __typename
    jsonCompletion
    name
    fbPosts {
      message
      imageLink
    }
    products {
      name
      featuredPhotoUrl
    }
  }
`;

const companyInformation = gql`
  fragment CompanyInformation on PromptCompanyInformation {
    __typename
    jsonCompletion
    websiteUrl
    app {
      name
    }
  }
`;

const productDescription = gql`
  fragment ProductDescription on PromptProductDescription {
    __typename
    productName
    completion
    jsonCompletion
    app {
      name
    }
    galleryPhoto {
      photoUrl
    }
    promptContext {
      keyFeatures
      keywords
      appSnapshot {
        categoryTree {
          primaryCategory
          secondaryCategories
        }
        businessDescription
      }
      targetAudience {
        ageRange
        educationLevel
        familyStatus
        gender
        incomeLevel
        interestHobbies
        occupation
        personality
        region
      }
      facebookPosts(fields: "permalink_url,created_time")
    }
  }
`;

const storeLaunch = gql`
  fragment StoreLaunch on PromptStoreLaunch {
    __typename
    jsonCompletion
    app {
      name
    }
    promptContext {
      keywords
      appSnapshot {
        categoryTree {
          primaryCategory
          secondaryCategories
        }
        businessDescription
      }
      targetAudience {
        ageRange
        educationLevel
        familyStatus
        gender
        incomeLevel
        interestHobbies
        occupation
        personality
        region
      }
    }
    promoCode {
      code
      endAt
      usageLimit
      usageCount
      minimumSpend
      value
      valueType
    }
    fbPosts {
      postUrl
      postCreatedAt
    }
  }
`;

export const Prompts = {
  fragments: {
    base,
    commentAutoreply,
    companyInformation,
    productDescription,
    storeLaunch,
  },
};
