// Core
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
// Extends
// Children
// Models / Helpers
// Queries / Mutations
import { fetchPrompt as promptQuery } from "@/api/queries/prompts";

/**
 * Extended Prompt retriever
 * @author Jan Canicon <jan@gorated.ph>
 *                     <jancanicon.work@gmail.com>
 * @since Version {1.4.3}
 *
 * Docs: https://gorated.atlassian.net/wiki/spaces/DMDS/pages/1856143582/Nested+Checkbox
 */
@Component({
  apollo: {
    promptQuery: {
      query: promptQuery,
      variables() {
        return {
          promptUuid: this.uuid,
        };
      },
      update({ fetchPrompt }) {
        this.prompt = fetchPrompt;
      },
      skip() {
        return !this.uuid;
      },
    },
  },
})
export default class ExtendedPromptMixin extends Vue {
  @Prop({ type: String, default: null })
  private uuid!: any;

  private prompt: any = {};

  private get formattedAnswer() {
    let completion = "";
    if (this.prompt.jsonCompletion) {
      completion = this.prompt.jsonCompletion.answer;
    } else {
      completion = this.prompt.completion;
    }

    return completion.replace(/\n/g, "<br>");
  }
}
