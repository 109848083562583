
// Core
import { Component, Mixins } from "vue-property-decorator";
// Extends
import ExtendedPromptMixin from "@/mixins/ExtendedPromptMixin";
// Children
import CopilotNotes from "@/components/GenAI/CopilotNotes.vue";
import PromptCard from "@/components/GenAI/PromptCard.vue";
// Models / Helpers
// Queries / Mutations

@Component({
  components: {
    CopilotNotes,
    PromptCard,
  },
})
export default class CompanyInfoPrompt extends Mixins(ExtendedPromptMixin) {
  private get storeOperations() {
    const ops: string[] = [];
    const storeLocs = this.prompt.jsonCompletion.answer.storeLocations;
    const openHours = this.prompt.jsonCompletion.answer.storeOpenHours;
    const closeHours = this.prompt.jsonCompletion.answer.storeCloseHours;

    const maxLen = Math.max(
      storeLocs?.length || 0,
      openHours?.length || 0,
      closeHours?.length || 0
    );

    for (let i = 0; i < maxLen; i++) {
      const open = openHours[i] || "N/A";
      const close = closeHours[i] || "N/A";
      const loc = storeLocs[i] || "N/A";
      ops.push({
        location: loc,
        hours: `${open} - ${close}`,
      });
    }

    return ops;
  }

  private get mapServiceOption() {
    switch (this.prompt.jsonCompletion.answer.serviceOption) {
      case "delivery":
        return "Delivery Only";
      case "instore":
        return "In-Store Only";
      default:
        return "Delivery and In-Store";
    }
  }
}
